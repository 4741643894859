
























import { Component, Vue } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';

@Component({
  components: {
    Sidebar,
  },
})
export default class Header extends Vue {
  public goHome(): void {
    this.$router.push('/home');
  }

  /* eslint-disable-next-line */
  public get canShowSidebar(): boolean {
    return true;
  }
}
