import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Main.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/News.vue'),
  },
  {
    path: '/teacher',
    name: 'teacher',
    component: () => import('../views/Teacher.vue'),
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('../views/Statistics.vue'),
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('../views/Feedback.vue'),
  },
  {
    path: '/exam',
    name: 'exam',
    component: () => import('../views/Exam.vue'),
  },
  {
    path: '/achievements',
    name: 'achievements',
    component: () => import('../views/Achievements.vue'),
  },
  {
    path: '/price-list',
    name: 'price-list',
    component: () => import('../views/PriceList.vue'),
  },
  {
    path: '/photos',
    name: 'photos',
    component: () => import('../views/PhotoGallery.vue'),
  },
  {
    path: '/videos',
    name: 'videos',
    component: () => import('../views/VideoGallery.vue'),
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/Contacts.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
