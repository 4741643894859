






import { Component, Vue } from 'vue-property-decorator';

@Component
export default class StatisticsCard extends Vue {
  /* eslint-disable */
  public scrollToTop(): void {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
}
