
















import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import SubHeader from '@/components/SubHeader.vue';
import ScrollUpButton from '@/components/ScrollUpButton.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/styles/main.scss';

@Component({
  components: {
    Header,
    Footer,
    SubHeader,
    ScrollUpButton,
  },
})
export default class App extends Vue {
  /* eslint-disable */
  public windowTop = 0;

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  }

  public get canShowSubHeader(): boolean {
    return window.innerWidth > 1200;
  }

  public get showScrollButton(): boolean {
    return this.windowTop > 400;
  }

  public onScroll(e) {
    this.windowTop = window.scrollY;
  }
}
